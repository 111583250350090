import React from 'react';
import { Redirect } from 'react-router-dom';
/*

import Footer from './Footer';
import Header from './Header';*/

const Consultant = () => {
    return(<><Redirect to="/announcement/25" /></>);
    /*return (
        <div className="background background-consultant">
            <div className="overlay">
                <div className="container">
                    <Header />
                    <h1>Konsulenttjenester</h1>
                    <div>
                        Vi løser det meste innen IT for deg! Vi kan hjelpe til med både smått og stort.<br />
                        Våre tjenester innebærer blant annet:<br />
                        Support ved fjernstyring.<br />
                        Utvikling.<br />
                        Oppsett av servere.<br />
                        Oppsett av nettsider.<br />
                        <Link to="/network">Nettverkstjenester.</Link><br />
                        Og mye mer!
                        <br /><br />
                        Er det noe du vil ha hjelp med? Benytt <Link to="/contact">kontaktsskjemaet</Link> så finner vi en løsning som passer deg!
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );*/
};

export default Consultant;