import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
    return (
        <nav>
            <Link to="/contact">Kontakt oss</Link>
            <a href="https://norskhosting.no/kunde/index.php?rp=/login">Logg inn</a>
        </nav>
    );
};

export default Navigation;