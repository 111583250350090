import React, { useRef, useState } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';

const DomainBar = () => {

    const [recaptchaToken, setToken] = useState();
    const recaptcha = useRef();

    const verifyCallback = (recaptchaToken) => {
        setToken(recaptchaToken);
    }

    return (
        <div className="domainbar">
            <ReCaptcha
                ref={recaptcha}
                sitekey="6LfhgLYUAAAAANS26cfFpihl0QiPigByKUH03Git"
                action='submit'
                verifyCallback={verifyCallback}
            />
            <form method="POST" id="domainform" className="domainform" action="https://www.norskhosting.no/kunde/cart.php?a=add&domain=register">
                <input type="text" name="query" placeholder="Søk etter ditt domene. (F.eks minside.no)" />
                {recaptchaToken ? 
                    <>
                        <input type="hidden" name="captcha" value={recaptchaToken} />
                        <button type='submit'>Søk</button>
                    </>
                : 
                    <button type='submit' disabled={true}>Søk</button>
                }
                
            </form>
        </div>
    );
};

export default DomainBar;