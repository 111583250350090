import React from 'react';

const Intro = () => {
    return (
        <div className="intro">
            Norsk kundeservice, norske konsulenter og servere i Norge. Helt enkelt - Norsk Hosting.
        </div>
    );
};

export default Intro;