import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import ProductBox from './ProductBox';

const Minecraft = () => {

    const [products, setProducts] = useState();

    useEffect(() => {
        (async () => {
            const result = await fetch("https://norskhosting.no/api/?a=minecraft");
            const json = await result.json();
            await setProducts(json.products.product);
        })();
    }, [])
    let displayProducts = "";
    if(products){
        displayProducts = products.map(prod => {
            const price = prod.pricing.NOK.monthly.split(".")[0];
            let link = "";
            let content = "";
            const descArray = prod.description.split("\n");
            const descRam = descArray[0].split(": ")[1];
            const descPlayers = descArray[1].split(": ")[1];
            const descVersion = descArray[2].split(": ")[0];
            content = (<>{descRam} RAM<br />{descPlayers} spillere*<br />{descVersion}<br /><br />Pris: {price} kr/mnd</>);
            if(prod.name === "Turtle"){
                link = "mc-turtle";
            }else if(prod.name === "Piglin"){
                link = "mc-piglin";
            }else if(prod.name === "Ender Dragon"){
                link = "mc-dragon";
            }
            return(<ProductBox key={prod.pid} boxClass={link} link={"https://norskhosting.no/kunde/cart.php?a=add&pid=" + prod.pid} linkIsExternal={true} title={prod.name} content={content} centerContent={true} />);
        });
    }
    return (
        <div className="background background-minecraft">
            <div className="overlay">
                <div className="container">
                    <Header />
                    <main>
                        <img src="/images/minecraft-logo.png" alt="Minecraft Logo" />
                        <div className="break"></div>
                        <h1>Hvor mye kraft trenger du?</h1>
                        <div className="break"></div>
                        {products ? displayProducts : <Loading /> }
                        <div className="break"></div>
                        <div>
                            <b>Trenger du mer ytelse?</b> Vennligst <Link to="/contact">kontakt oss</Link>.<br />
                            <br />
                            <h2>Inkludert i samtlige pakker:</h2>
                            <ul>
                                <li>Hostet i Oslo, Norge</li>
                                <li>Multicraft kontrollpanel</li>
                                <li>Serveren er tilgjengelig umiddelbart</li>
                                <li>Last opp din egen verden</li>
                                <li>FTP-tilgang</li>
                                <li>Support på norsk</li>
                            </ul>
                            <small>*: Antall spillere er et estimat basert på et en spiller bruker omtrent 100MB<br />Du står selv fritt til å velge max antall spillere på din server.</small>
                            <br />
                            
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Minecraft;