import React from 'react';
import { Link } from 'react-router-dom';

const ProductBox = (props) => {

    const {title, boxClass, link, content, centerContent, linkIsExternal} = props;
    const productBoxClass = "productbox " + boxClass;
    let descClass = "content";
    if(centerContent){
        descClass = "content centercontent";
    }

    return (
        <div class={productBoxClass}>
            { linkIsExternal ? 
            <a href={link}>
                <div className="inner">
                    <h1>{title}</h1>
                    <div className={descClass}>{content}</div>
                </div>
            </a> 
            : 
            <Link to={link}>
                <div className="inner">
                    <h1>{title}</h1>
                    <div className={descClass}>{content}</div>
                </div>
            </Link> }
            
                
                
        </div>
    );
};

export default ProductBox;