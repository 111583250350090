import React, { useRef, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { ReCaptcha } from 'react-recaptcha-v3';
import Loading from './Loading';

const Contact = () => {

    const recaptcha = useRef();
    const [token, setToken] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [resultMessage, setResultMessage] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [isError, setIsError] = useState(false);

    const name = useRef();
    const email = useRef();
    const subject = useRef();
    const message = useRef();

    const verifyCallback = (recaptchaToken) => {
        setToken(recaptchaToken);
    }

    /*const updateToken = () => {
        // you will get a new token in verifyCallback
        recaptcha.execute();
    }*/

    async function submit(e){
        e.preventDefault();
        setSubmitting(true);
        const data = {
            token: token,
            name: name.current.value,
            email: email.current.value,
            subject: subject.current.value,
            message: message.current.value
        }

        if(!data.name.length || !data.email.length || !data.subject.length || !data.message.length){
            setIsError(true);
            setSubmitting(false);
        }else{
            const response = await fetch("https://norskhosting.no/api/?a=openticket", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const json = await response.json();
            if(json.result === 'success'){
                setResultMessage("Takk for din henvendelse! Vi kommer tilbake til deg så fort vi kan.");
            }else{
                setResultMessage("Noe gikk galt. Vennligst send oss en e-post direkte på hei@norskhosting.no");
            }
            setSubmitted(true);
            setSubmitting(false);
        }
    }

    return (
        <div className="background background-contact">
            <div className="overlay">
                <div className="container">
                    <Header />
                    <h1>Kontakt oss</h1>
                    
                    <ReCaptcha
                        ref={recaptcha}
                        sitekey="6LfhgLYUAAAAANS26cfFpihl0QiPigByKUH03Git"
                        action='submit'
                        verifyCallback={verifyCallback}
                    />
                    {submitted ? (
                        <>
                            {resultMessage}
                        </>
                    ) : (
                    <>
                        <div>Bruk skjemaet under for å ta kontakt med oss.</div>
                        <div></div>
                        {isError ? <div class="error">Vennligst fyll ut alle feltene før du sender inn skjemaet.</div> : null}
                        {submitting ? <Loading /> : 
                        <form method="POST" className="contactForm" onSubmit={submit}>
                            <label htmlFor="name">Navn:</label>
                            <input id="name" type="text" name="name" ref={name} tabindex="1" />
                            <label htmlFor="email">E-post:</label>
                            <input id="email" type="email" name="email" ref={email} tabindex="2" />
                            <label htmlFor="subject">Emne:</label>
                            <input id="subject" type="text" name="subject" ref={subject} tabindex="3" />
                            <label htmlFor="message">Melding:</label>
                            <textarea id="message" name="message" ref={message} tabindex="4" ></textarea>
                            <div><small>Dette feltet støtter bruk av <b><a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer" tabindex="6">markdown</a></b>.</small></div>
                            <button type="submit" tabindex="5">Send inn</button>
                        </form>
                        }
                        
                    </>
                    )}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Contact;