import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import ShortAnnouncement from './ShortAnnouncement';

const Announcements = () => {

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const result = await fetch("https://norskhosting.no/api/?a=announcements");
            const json = await result.json();
            if(json.announcements){
                json.announcements.announcement.forEach((article) => {
                    if(parseInt(article.published) === 1){
                        setArticles(prevArticles => [...prevArticles, article]);
                    }
                });
                setLoading(false);
            }
        })();
    }, []);

    let showArticles = "";
    if(articles.length){
        showArticles = articles.map(article => <ShortAnnouncement title={article.title} date={article.date} announcement={article.announcement} id={article.id} key={article.id} />);
    }

    return (
        <div>
            {articles.length ? <h1>Nyheter</h1> : null }
            {loading ? <Loading /> : <div className="align-left">{showArticles}</div> }
        </div>
    );
};

export default Announcements;