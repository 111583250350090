import React from 'react';

const Loading = () => {
    return (
        <div align="center">
            <img src="/images/loading.gif" alt="Loading" />
        </div>
    );
};

export default Loading;