import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <small>Norsk Hosting AS &copy; 2021 - <Link to="/announcement/22">Avtalevilkår</Link> - <Link to="/announcement/23">Personvernserklæring</Link></small>
        </footer>
    );
};

export default Footer;