import React from 'react';
import DomainBar from './DomainBar';
import Footer from './Footer';
import Header from './Header';
import Intro from './Intro';
import HomePageContent from './HomePageContent';

const HomePage = () => {
    return (
        <div className="background background-main">
            <div className="overlay">
                <div className="container">
                    <Header />
                    <Intro />
                    <DomainBar />
                    <HomePageContent />
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default HomePage;