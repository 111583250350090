import React from 'react';
import Announcements from './Announcements';
import ProductBox from './ProductBox';

const HomePageContent = () => {
    return (
        <main>
            <h1>Hva er du ute etter?</h1>
            <div className="break"></div>
            <ProductBox title="Webhotell" boxClass="server" link="https://norskhosting.no/kunde/index.php?rp=/store/vanlige-webhotell" linkIsExternal={true} content="Fra kr 24/mnd" />
            <ProductBox title="Minecraft" boxClass="minecraft" link="/minecraft" content="Fra kr 55/mnd" />
            <ProductBox title="Teamspeak 3" boxClass="teamspeak" link="https://norskhosting.no/kunde/cart.php?a=add&pid=86" linkIsExternal={true} content="Fra kr 23/mnd" />
            <ProductBox title="VPS" boxClass="vps" link="https://norskhosting.no/kunde/cart.php?a=add&pid=149" linkIsExternal={true} content="Fra kr 50/mnd" />
            <div className="break"></div>
            <h1>Andre tjenester</h1>
            <div className="break"></div>
            <ProductBox title="Nettverk" boxClass="network" link="/network" content="Vi setter opp nettverk for din bedrift." centerContent={true} />
            <ProductBox title="Konsulenttjenester" boxClass="consultant" link="/consultant" content="Vi kan hjelpe til med det meste innen IT." centerContent={true} />
            <div className="break"></div>
            <Announcements />
        </main>
    );
};

export default HomePageContent;