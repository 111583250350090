import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <div className="logo">
            <Link to="/"><img src="images/nhlogo_small.png" alt="Logo" /></Link>
        </div>
    );
};

export default Logo;