import React from 'react';
import { Link } from 'react-router-dom';

const ShortAnnouncement = (props) => {

    const div = document.createElement("div");
    div.innerHTML = props.announcement;
    const text = div.textContent || div.innerText || "";
    const shortText = text.substring(0,128) + "...";
    const parseDate = props.date.split(" ");
    const date = parseDate[0];
    const time = parseDate[1];
    const formatDate = date.split("-");
    const year = formatDate[0];
    const month = formatDate[1];
    const day = formatDate[2];
    const formatTime = time.split(":");
    const hour = formatTime[0];
    const minute = formatTime[1];

    return (
        <div class="article">
            <Link to={"/announcement/" + props.id}><h2>{props.title}</h2></Link>
            <small>{day}.{month}.{year} {hour}:{minute}</small>
            <div>{shortText}</div>
        </div>
    );
};

export default ShortAnnouncement;